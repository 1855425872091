<script setup>
import { message } from "ant-design-vue";

const checkNetwork = () => {
  if (window.navigator.onLine) {
    message.success("Başarılı, ana sayfaya yönlendiriliyorsunuz.");
  } else {
    message.error("İnternet bağlantısı bulunamadı, lütfen tekrar deneyiniz.");
  }
};
</script>

<template>
  <div class="offline-view">
    <div class="offline-info-box">
      <img
        src="/img/static/error.png"
        width="128"
        height="128"
        alt="Bağlantı yok"
      />
      <h3 class="offline-info-box-title is-heading mt-3">Bağlantı Problemi</h3>
      <p class="no-margin is-description text-center">
        Görünüşe göre bağlantınız koptu... <br />Lütfen bağlantını ayarlarınızı
        kontrol edip tekrar deneyin
      </p>
      <div class="mt-3 width-100">
        <a-button type="primary" block size="large" @click="checkNetwork"
          >Yeniden Dene</a-button
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offline-view {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-background-with-image);

  .offline-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .offline-info-box-title {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
</style>
